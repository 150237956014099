@font-face {
  src: url('./fonts/Lato-Regular.ttf');
  font-family: 'Regular';
}

@font-face {
  src: url('./fonts/BebasNeue-Regular.ttf');
  font-family: 'Bebas';
}

body {
  background-color: #202020;
  font-family: 'Regular', sans-serif;
  letter-spacing: -0.25px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.content-holder {
  text-align: center;
  width: 100%;
}

p {
  color: #999;
  font-size: 20px;
  max-width: 50%;
}

p.about {
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 16px;
  line-height: 1.6;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #999;
  text-decoration: none;
  font-family: "Courier New", Courier, monospace;
  letter-spacing: 1px;
  transition: all 0.2s ease;
  font-weight: bold;
}

.App-link:hover {
  color: #00ccff;
}

hr {
  height: 0;
  border: 0;
  border-bottom: 1px solid #444;
  width: 70%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.circle {
  position: fixed;
  border-radius: 50%;
  background: #333;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #666;
}

.small {
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium {
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large {
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge {
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge {
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1 {
  opacity: 0.2;
}

.shade2 {
  opacity: 0.5;
}

.shade3 {
  opacity: 0.7;
}

.shade4 {
  opacity: 0.8;
}

.shade5 {
  opacity: 0.9;
}

.title {
  font-family: 'Bebas', sans-serif;
  font-size: 44px;
  color: #efefef;
}

.subtitle {
  font-weight: bold;
  font-size: 28px;
  color: #efefef;
}

.ev-container {
  background: linear-gradient(180deg, rgba(32,32,32,1) 0%, rgba(0,0,0,1) 30%, rgba(10,10,10,1) 70%, rgba(32,32,32,1) 100%);
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
}

.headlight-holder {
  height: 250px;
  display: flex;
  flex-direction: row;
  justify-content: space-around; 
  align-items: center;
  max-width: 400px;
  margin: 16px auto;
}

.headlight-outer {
  width: 60px;
  height: 60px;
  background-color: rgba(209, 209, 209, 0.4);
  border-radius: 40px;
  -webkit-filter: blur(5px);
  filter: blur(2px);
}

.headlight-outer > .headlight-inner {
  width: 20px;
  height: 20px;
  background-color: #666;
  border-radius: 10px;
  margin: 20px auto;
}

.headlight-outer > .light {
  width: 160px;
  height: 160px;
  border-radius: 120px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.5s ease;
  background: radial-gradient(circle closest-side, #fff, transparent);
  position: absolute;
  margin-top: -110px;
  margin-left: -50px;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.headlight-outer[on="true"] > .light {
  -webkit-transform: scale(1);
  transform: scale(1);
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}

p.desktop {
  display: block;
}

p.mobile {
  display: none;
}

@media only screen and (max-width: 480px) {
  .circle {
    animation: none;
    display: none;
  }

  p {
    max-width: 100%;
    padding: 16px;
    font-size: 13px;
  }

  p.about {
    font-size: 13px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  p.desktop {
    display: none;
  }

  p.mobile {
    display: block;
  }

  .title {
    font-size: 28px;
  }

  .subtitle {
    font-size: 22px;
  }
}
